import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Media,
  Button,
  Label,
} from 'reactstrap';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';
import { Dropdown } from 'primereact/dropdown';

import avatar from '../../assets/images/users/avatar-1.jpg';
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';
import { FormText } from 'reactstrap';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import AvRadioGroup from 'availity-reactstrap-validation/lib/AvRadioGroup';
import AvRadio from 'availity-reactstrap-validation/lib/AvRadio';
import { apiUrl } from '../../utils/constants';
import ApiHandling from '../../utils/ApiHandling';
import { Dialog } from 'primereact/dialog';
import { ToastInfo } from '../../components/Common/Toast';
import OtpInput from 'react-otp-input';
import Dropzone from 'react-dropzone';
// import { FaQuestionCircle } from 'react-icons/fa';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
const UserProfile = (props) => {
  const [isPasswordEditing, setIsPasswordEditing] = useState(false);

  const [coldLeadAge, setColdLeadAge] = useState(null);

  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    mobileNumber: '',
    password: '',
    userType: '',
    userRole: '',
    profilePic: '',
    logId: '',
  });

  const [builderDetails, setBuilderDetails] = useState({
    registeredName: '',
    displayName: '',
    builderLogo: '',
  });

  const [productDialog, setProductDialog] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [selectedFiles, setselectedFiles] = useState([]);

  const leadActivityDropdownOptions = [
    { label: 'Inactive: More than 15 Days', value: 15 },
    { label: 'Inactive: More than 30 Days', value: 30 },
    { label: 'Inactive: More than 45 Days', value: 45 },
    { label: 'Inactive: More than 60 Days', value: 60 },
  ];

  const decodedToken = JSON.parse(localStorage.getItem('authUser'));

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'));

      setUserDetails({
        ...userDetails,
        firstName: obj.firstName,
        mobileNumber: obj.mobileNumber,
        lastName: obj.lastName,
        emailId: obj.emailId,
        userRole: obj.userRole,
        userType: obj.userType,
        profilePic: obj.profilePic,
        logId: obj.logId,
      });
    }
  }, [props.success]);

  const onPasswordRadioInputChange = (e, value) => {
    if (value !== undefined) {
      const boolValue = JSON.parse(value);
      setIsPasswordEditing(boolValue);
    }
  };

  function handleValidSubmit(event, values) {
    let product = { ...userDetails };
    product.userType = userDetails.userType;

    if (isPasswordEditing) {
      product = {
        ...product,
        password: values.password,
      };
    }

    if (
      !isPasswordEditing ||
      (isPasswordEditing && values.password === values.confirmPassword)
    ) {
      ApiHandling.fetchApi(
        `${apiUrl}/global/edit/profile`,
        { profileDetails: product },
        'POST'
      ).then((response) => {
        if (response?.status === 200) {
          if (response?.data) {
            let state = {
              ...product,
              ...values,
              password: '',
            };

            document.getElementById('radio-changePassword-false').click();
            const obj = JSON.parse(localStorage.getItem('authUser'));
            obj.firstName = state.firstName;
            obj.lastName = state.lastName;
            obj.emailId = state.emailId;
            obj.mobileNumber = state.mobileNumber;
            localStorage.setItem('authUser', JSON.stringify(obj));
            setUserDetails(state);
          }
        }
      });
    }
  }

  const onChangeNumberClicked = () => {
    setProductDialog(true);
  };

  const hideDialog = () => {
    setProductDialog(false);
  };

  const onFormSubmit = (event, invalidFields, values) => {
    if (!invalidFields.length) {
      let product = {
        ...values,
        userType: userDetails.userType,
      };

      if (isOtpSent)
        product = {
          ...product,
          otp,
        };

      if (
        !isOtpSent ||
        (isOtpSent &&
          otp &&
          values.password === values.confirmPassword &&
          otp.length === 6)
      ) {
        let requestType = isOtpSent ? 'POST' : 'PUT';
        let currentApiUrl = isOtpSent
          ? `${apiUrl}/global/verifyOtp/changeMobileNo`
          : `${apiUrl}/global/otp/change/mobileNo`;
        ApiHandling.fetchApi(
          currentApiUrl,
          { profileDetails: product },
          requestType
        ).then((response) => {
          if (response?.status === 200) {
            if (response?.data) {
              let data = response.data;
              if (!isOtpSent) {
                setIsOtpSent(true);
              } else {
                hideDialog();
              }
            }
          }
        });
      } else {
        if (isOtpSent && (otp === '' || otp.length !== 6)) {
          ToastInfo('Please Enter Valid OTP');
        }
        if (values.password !== values.confirmPassword) {
          ToastInfo('Passwords does not match');
        }
      }
    }
  };

  const userDetailsChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const onOtpChange = (value) => {
    setOtp(value);
  };

  const uploadProfilePic = async (contactId, files, isBuilderLogo) => {
    const uploadedData = new FormData();
    uploadedData.append('file', files[0]);

    const response = await ApiHandling.fetchApi(
      `${apiUrl}/global/file/uploadFile?type=${
        isBuilderLogo ? 'builderLogo' : 'profilePic'
      }&id=${contactId}`,
      uploadedData
    );
    if (response?.data) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve('');
    }
  };

  function handleAcceptedImages(files, isBuilderLogo) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    uploadProfilePic(userDetails.logId, files, isBuilderLogo).then(
      (imageUrl) => {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        if (!isBuilderLogo) {
          // dont' store builder logo as profile pic
          obj.profilePic = imageUrl;
          localStorage.setItem('authUser', JSON.stringify(obj));
          window.location.reload();
        } else {
          setBuilderDetails({ ...builderDetails, builderLogo: imageUrl });
        }
        localStorage.setItem('authUser', JSON.stringify(obj));
      }
    );
    // setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem('authUser'));
    if (obj.userType === 'agent' || obj.userType === 'marketingAgency') {
      ApiHandling.fetchApi(
        `${apiUrl}/${decodedToken.userType}?${
          decodedToken.userType === 'agent' ? 'agentId=' : 'marketingAgencyId='
        }${obj.foreKey}`
      ).then((response) => {
        if (response?.data) {
          setColdLeadAge(response.data[0].ageConfig);
        }
      });
    }
  }, []);

  const handleSaveAgedLeads = async (e, v) => {
    e.preventDefault();

    try {
      ApiHandling.fetchApi(
        `${apiUrl}/global/edit/ageleads`,
        { leadAgeConfig: coldLeadAge },
        'POST'
      ).then((response) => {
        if (response.data) {
          setColdLeadAge(response.data?.AGE_CONFIG);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (
      (decodedToken && decodedToken.userType === 'builder') ||
      decodedToken.userType === 'marketingAgency'
    ) {
      const id = decodedToken.user;
      ApiHandling.fetchApi(
        `${apiUrl}/${decodedToken.userType}?id=${decodedToken.foreKey}`
      ).then((response) => {
        if (response?.status === 200) {
          setBuilderDetails(response.data[0]);
        }
      });
    }
  }, []);
  console.log(builderDetails);

  const handleBuilderDetails = async (e) => {
    e.preventDefault();
    console.log('yyyyyyyyyy');
    try {
      const builderUpdatedDetails = {
        registeredName: builderDetails.registeredName,
        displayName: builderDetails.displayName,
      };
      console.log(builderUpdatedDetails);
      const response = await ApiHandling.fetchApi(
        `${apiUrl}/${decodedToken.userType}?id=${decodedToken.foreKey}`,
        { builderDetails },
        'PUT'
      );

      if (response?.data) {
        setBuilderDetails(response.data);
      }
    } catch (error) {}
  };

  if (!decodedToken) {
    // You can return null, render a loading spinner, or handle the case accordingly
    return null;
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Skote" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Media style={{ alignItems: 'center' }}>
                    <div className="mr-3">
                      <Form>
                        <Dropzone
                          accept="image/*"
                          multiple={false}
                          onDrop={(acceptedFiles) => {
                            handleAcceptedImages(acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              className="pic-upload-container bx bx-plus"
                            >
                              <input
                                id="agentProfilePicUpload"
                                {...getInputProps()}
                              />
                              {selectedFiles.length ? (
                                selectedFiles.map((f, i) => {
                                  return (
                                    <img
                                      data-dz-thumbnail=""
                                      // height="80"
                                      className="avatar-md rounded-circle bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  );
                                })
                              ) : (
                                <span className="profile-pic-placeholder">
                                  {userDetails.profilePic ? (
                                    <img
                                      data-dz-thumbnail=""
                                      // height="80"
                                      className="avatar-md rounded-circle bg-light"
                                      alt="profile-pic"
                                      src={
                                        userDetails.profilePic
                                          ? userDetails.profilePic
                                          : avatar
                                      }
                                    />
                                  ) : (
                                    <i className="text-muted fas fa-plus"></i>
                                  )}
                                </span>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </Form>
                      {/* <img
                        src={
                          userDetails.profilePic
                            ? userDetails.profilePic
                            : avatar
                        }
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      /> */}
                    </div>
                    <Media
                      body
                      className="align-self-center profile-name-mobile"
                    >
                      <div className="text-muted">
                        <h5>
                          {userDetails.firstName + ' ' + userDetails.lastName}
                        </h5>
                        <p className="mb-1">{userDetails.emailId}</p>
                        {decodedToken.userType === 'agent' ? (
                          <p className="mb-0">Agent</p>
                        ) : (
                          <p className="mb-0">{userDetails.userRole}</p>
                        )}
                        <p className="mb-1">{userDetails.mobileNumber}</p>
                      </div>
                      <Button
                        className="change-number"
                        color="primary"
                        onClick={onChangeNumberClicked}
                      >
                        <span className="mr-2">Change Mobile Number</span>
                        <i className="fas fa-repeat"></i>
                      </Button>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Edit Profile</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal row"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <AvGroup className="col-4">
                  <Label for="firstName" required>
                    First Name
                  </Label>
                  <AvInput
                    name="firstName"
                    value={userDetails.firstName}
                    onChange={userDetailsChangeHandler}
                    className="form-control"
                    placeholder="Enter First Name"
                    type="text"
                    required
                  />
                </AvGroup>
                <AvGroup className="col-4">
                  <Label for="lastName" required>
                    Last Name
                  </Label>
                  <AvInput
                    name="lastName"
                    value={userDetails.lastName}
                    onChange={userDetailsChangeHandler}
                    className="form-control"
                    placeholder="Enter Last Name"
                    type="text"
                    required
                  />
                </AvGroup>
                <AvGroup className="col-4">
                  <Label for="emailId">Email</Label>
                  <AvInput
                    name="emailId"
                    value={userDetails.emailId}
                    onChange={userDetailsChangeHandler}
                    className="form-control"
                    placeholder="Enter Email"
                    type="email"
                  />
                </AvGroup>
                <AvGroup className="col-4">
                  <Label for="changePassword" required>
                    Change Password
                  </Label>
                  <AvRadioGroup
                    inline
                    name="changePassword"
                    className="mt-2"
                    required
                    onChange={onPasswordRadioInputChange}
                    value="false"
                  >
                    <AvRadio label="Yes" value="true" />
                    <AvRadio label="No" value="false" />
                  </AvRadioGroup>
                </AvGroup>
                {isPasswordEditing && (
                  <>
                    <AvGroup className="col-4">
                      <Label for="password" required>
                        Password
                      </Label>
                      <AvInput
                        name="password"
                        className="form-control"
                        placeholder="Enter Password"
                        required
                      />
                    </AvGroup>
                    <AvGroup className="col-4">
                      <Label for="confirmPassword" required>
                        Confirm Password
                      </Label>
                      <AvInput
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        required
                      />
                    </AvGroup>
                  </>
                )}
                <div className=" col-12 mt-4">
                  <Button type="submit" color="danger">
                    Update
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>

          {decodedToken && decodedToken.userType === 'builder' && (
            <>
              <h4 className="card-title mb-4">Edit Builder Profile</h4>

              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal row"
                    onSubmit={handleBuilderDetails}
                  >
                    <AvGroup className="col-4 d-flex flex-row align-items-center">
                      <Dropzone
                        accept="image/*"
                        multiple={false}
                        onDrop={(acceptedFiles) => {
                          handleAcceptedImages(acceptedFiles, true);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="pic-upload-container bx bx-plus"
                            style={{ marginRight: '15px' }}
                          >
                            <input
                              id="agentProfilePicUpload"
                              {...getInputProps()}
                            />

                            <span className="profile-pic-placeholder">
                              {builderDetails.builderLogo ? (
                                <img
                                  data-dz-thumbnail=""
                                  className="avatar-md rounded-circle bg-light"
                                  alt="profile-pic"
                                  src={builderDetails.builderLogo || avatar}
                                  style={{
                                    maxWidth: '80px',
                                    maxHeight: '80px',
                                  }}
                                />
                              ) : (
                                <i className="text-muted fas fa-plus"></i>
                              )}
                            </span>
                          </div>
                        )}
                      </Dropzone>
                      <div>
                        <Label for="builderLogoUpload" className="upload-logo">
                          {decodedToken.userType === 'builder' ||
                          decodedToken.userType === 'marketingAgency'
                            ? 'Upload Builder Logo '
                            : ''}
                        </Label>{' '}
                        <br />
                        <Label
                          for="builderLogoUpload"
                          className="block upload-logo font-size-12"
                        >
                          {decodedToken.userType === 'builder' ||
                          decodedToken.userType === 'marketingAgency'
                            ? '240 x 70 pixels is recommended.'
                            : ''}
                        </Label>
                      </div>
                    </AvGroup>

                    <AvGroup className="col-4">
                      <Label for="registeredName" required>
                        Registered Name
                      </Label>
                      <AvInput
                        name="registeredName"
                        value={builderDetails.registeredName}
                        onChange={(e) =>
                          setBuilderDetails({
                            ...builderDetails,
                            registeredName: e.target.value,
                          })
                        }
                        className="form-control"
                        placeholder="Enter First Name"
                        type="text"
                      />
                    </AvGroup>
                    <AvGroup className="col-4">
                      <Label for="displayName" required>
                        Display Name
                      </Label>
                      <AvInput
                        name="displayName"
                        value={builderDetails.displayName}
                        onChange={(e) =>
                          setBuilderDetails({
                            ...builderDetails,
                            displayName: e.target.value,
                          })
                        }
                        className="form-control"
                        placeholder="Enter Last Name"
                        type="text"
                      />
                    </AvGroup>

                    <AvGroup className="col-4">
                      <Label for="aboutBuilder" required>
                        About Builder
                      </Label>
                      <AvField
                        type="textarea"
                        name="aboutBuilder"
                        value={builderDetails.aboutBuilder}
                        onChange={(e) =>
                          setBuilderDetails({
                            ...builderDetails,
                            aboutBuilder: e.target.value,
                          })
                        }
                      />
                    </AvGroup>

                    {/* <AvGroup className="col-4">
                  <Label for="aboutBuilder" required>
                    About Builder
                  </Label>
                  <AvInput
                    name="aboutBuilder"
                    value=''
                    onChange={userDetailsChangeHandler}
                    className="form-control"
                    placeholder="Enter Email"
                    type="email"
                    required
                  />
                </AvGroup> */}

                    {/* <AvGroup className="col-4">
                  <Label for="emailId" required>
                    Email
                  </Label>
                  <AvInput
                    name="emailId"
                    value={userDetails.emailId}
                    onChange={userDetailsChangeHandler}
                    className="form-control"
                    placeholder="Enter Email"
                    type="email"
                    required
                  />
                </AvGroup>
                <AvGroup className="col-4">
                  <Label for="changePassword" required>
                    Change Password
                  </Label>
                  <AvRadioGroup
                    inline
                    name="changePassword"
                    className="mt-2"
                    required
                    onChange={onPasswordRadioInputChange}
                    value="false"
                  >
                    <AvRadio label="Yes" value="true" />
                    <AvRadio label="No" value="false" />
                  </AvRadioGroup>
                </AvGroup>
                {isPasswordEditing && (
                  <>
                    <AvGroup className="col-4">
                      <Label for="password" required>
                        Password
                      </Label>
                      <AvInput
                        name="password"
                        className="form-control"
                        placeholder="Enter Password"
                        required
                      />
                    </AvGroup>
                    <AvGroup className="col-4">
                      <Label for="confirmPassword" required>
                        Confirm Password
                      </Label>
                      <AvInput
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        required
                      />
                    </AvGroup>
                  </>
                )} */}
                    <div className=" col-12 mt-4">
                      <Button type="submit" color="danger">
                        Update
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: '450px' }}
        header="Change Mobile Number"
        modal
        className="p-fluid city-details-dialog"
        onHide={hideDialog}
      >
        <AvForm className="form-horizontal mt-4" onSubmit={onFormSubmit}>
          <div className="form-group">
            <AvField
              name="mobileNumber"
              label="Mobile Number"
              className="form-control"
              placeholder="Enter Mobile number"
              type="number"
              required
              disabled={isOtpSent}
            />
            {isOtpSent && (
              <>
                <label>OTP</label>
                <OtpInput
                  value={otp}
                  onChange={onOtpChange}
                  numInputs={6}
                  isInputNum={true}
                  className="otp-input-field"
                />
              </>
            )}
          </div>
          <Row className="form-group mt-4">
            <Col className="text-right">
              <button
                className="btn btn-primary w-md waves-effect waves-light"
                type="submit"
              >
                {!isOtpSent ? 'Send OTP' : 'Change Mobile Number'}
              </button>
            </Col>
          </Row>
        </AvForm>
      </Dialog>

      {decodedToken.userType !== 'bbUser' && (
        <div className="page-content">
          <Container fluid style={{ marginTop: '-150px' }}>
            <h4 className="card-title mb-4">Setting</h4>

            <Card>
              <CardBody>
                <AvForm
                  className="form-horizontal row"
                  onValidSubmit={(e, v) => {
                    handleSaveAgedLeads(e, v);
                  }}
                >
                  <AvGroup className="col-4">
                    <div>
                      <Label for="agedLeads" required>
                        Cold Lead Age
                        <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                          <Tooltip target=".tooltop" position="top">
                            This setting is used to make a decision about cold
                            leads
                          </Tooltip>
                          <span
                            className="tooltop"
                            style={{
                              padding: '0 6px',
                              background: 'black',
                              borderRadius: '50%',
                              color: 'white',
                            }}
                          >
                            &#63;
                          </span>
                        </span>
                      </Label>
                    </div>
                    <div>
                      <Dropdown
                        id="leadActivityDropdown"
                        value={coldLeadAge}
                        options={leadActivityDropdownOptions}
                        style={{
                          height: '32px',
                          lineHeight: '1',
                          marginRight: '20px',
                        }}
                        onChange={(e) => setColdLeadAge(e.target.value)}
                        placeholder={`Set Cold Lead Age`}
                        required
                        filter
                      />
                    </div>
                  </AvGroup>
                  <div className="col-12 mt-2">
                    <Button type="submit" color="danger">
                      Save
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserProfile;
