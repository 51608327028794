// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBok0Ajtw9BQQ5d6nUHSnTZyIM3E50N3Wc",
//   authDomain: "crmbbapp.firebaseapp.com",
//   projectId: "crmbbapp",
//   storageBucket: "crmbbapp.appspot.com",
//   messagingSenderId: "535051346932",
//   appId: "1:535051346932:web:0ec0355aa026096e81cfa1",
// };

// replacing web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyARz-HE3fUeNFklpq9IX7PpIq9NQDA1jLw",
  authDomain: "realtorworks-89f65.firebaseapp.com",
  projectId: "realtorworks-89f65",
  storageBucket: "realtorworks-89f65.appspot.com",
  messagingSenderId: "338627372093",
  appId: "1:338627372093:web:ad28e6979cee0d04d57654",
  measurementId: "G-WDS2FVPCE3"
};

console.log("process.env", process.env);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
