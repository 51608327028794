import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  ButtonGroup,
  Button,
} from 'reactstrap';

// action
import {
  registerUser,
  apiError,
  registerUserFailed,
} from '../../store/actions';

import { AvForm, AvField } from 'availity-reactstrap-validation';

// Redux
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

// import images
import profileImg from '../../assets/images/profile-img.png';
import { apiUrl } from '../../utils/constants';
import ApiHandling from '../../utils/ApiHandling';
import { ToastInfo } from '../../components/Common/Toast';
import { InputText } from 'primereact/inputtext';

import app from '../../initFirebase'; //Import the initialized Firebase app
import { getAnalytics, logEvent } from 'firebase/analytics';

const Register = (props) => {
  const analytics = getAnalytics(app); // Initialize analytics
  const history = useHistory();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [signUpData, setSignUpData] = useState({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    password: '',
    confirmPassword: '',
  });

  const [userType, setUserType] = useState('agent');
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    setSignUpData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  // handleValidSubmit
  const signUp = () => {
    // The existing signup conditions
    if (
      userType === 'agent' &&
      signUpData.firstName &&
      signUpData.lastName &&
      signUpData.mobileNumber &&
      signUpData.password &&
      signUpData.password === signUpData.confirmPassword
    ) {
      let currentApiUrl = `${apiUrl}/agent/signup`;
      const data = {};

      data.agentDetails = {
        mobileNumber: signUpData.mobileNumber,
        firstName: signUpData.firstName,
        lastName: signUpData.lastName,
        password: signUpData.password,
      };

      if (/^\d{10}$/.test(signUpData.mobileNumber)) {
        ApiHandling.fetchApi(currentApiUrl, data, 'POST').then((response) => {
          if (response?.status === 200) {
            setIsOtpSent(true);
            logEvent(analytics, 'otp_sent', { mobileNumber: signUpData.mobileNumber }); // Log OTP sent event
          }
        });
      } else {
        ToastInfo(
          'Invalid mobile number. Please enter a valid 10-digit number.'
        );
      }
    } else if (
      (userType === 'builder' || userType === 'marketingAgency') &&
      signUpData.registeredName &&
      signUpData.displayName &&
      signUpData.mobileNumber &&
      signUpData.password &&
      signUpData.password === signUpData.confirmPassword
    ) {
      let currentApiUrl =
        userType === 'builder'
          ? `${apiUrl}/builder/signup`
          : `${apiUrl}/marketingAgency/signup`;
      const data = {};
      if (userType === 'builder') {
        data.builderDetails = {
          mobileNumber: signUpData.mobileNumber,
          registeredName: signUpData.registeredName,
          displayName: signUpData.displayName,
          password: signUpData.password,
        };
      } else {
        data.marketingAgencyDetails = {
          mobileNumber: signUpData.mobileNumber,
          registeredName: signUpData.registeredName,
          displayName: signUpData.displayName,
          password: signUpData.password,
        };
      }

      if (/^\d{10}$/.test(signUpData.mobileNumber)) {
        ApiHandling.fetchApi(currentApiUrl, data, 'POST').then((response) => {
          if (response?.status === 200) {
            setIsOtpSent(true);
            logEvent(analytics, 'otp_sent', { mobileNumber: signUpData.mobileNumber }); // Log OTP sent event
            console.log("OTP sent event logged successfully!"); // Console log for success
          }
        });
      } else {
        ToastInfo(
          'Invalid mobile number. Please enter a valid 10-digit number.'
        );
      }
    } else {
      if (isOtpSent && (otp === '' || otp.length !== 6)) {
        ToastInfo('Please Enter Valid OTP');
      } else if (signUpData.password !== signUpData.confirmPassword) {
        ToastInfo('Passwords does not match');
      } else {
        ToastInfo('Please fill all the details');
      }
    }
  };

  const verifyOtp = () => {
    if (otp) {
      const data = {};
      if (userType === 'builder') {
        data.builderDetails = {
          mobileNumber: signUpData.mobileNumber,
          registeredName: signUpData.registeredName,
          displayName: signUpData.displayName,
          password: signUpData.password,
        };
      } else if (userType === 'marketingAgency') {
        data.marketingAgencyDetails = {
          mobileNumber: signUpData.mobileNumber,
          registeredName: signUpData.registeredName,
          displayName: signUpData.displayName,
          password: signUpData.password,
        };
      } else if (userType === 'agent') {
        data.agentDetails = {
          firstName: signUpData.firstName,
          lastName: signUpData.lastName,
          mobileNumber: signUpData.mobileNumber,
          password: signUpData.password,
        };
      }
      let currentApiUrl = `${apiUrl}/${userType}/verify/otp?mobileNumber=${signUpData.mobileNumber}&otp=${otp}`;
      ApiHandling.fetchApi(currentApiUrl, data, 'POST').then((response) => {
        if (response?.status === 200) {
          logEvent(analytics, 'otp_verified', { mobileNumber: signUpData.mobileNumber }); // Log OTP verified event
          props.history.push('/login');
        } else {
          logEvent(analytics, 'otp_verification_failed', { error: 'Invalid OTP' }); //Log OTP verification failure
        }
      });
    } else {
      logEvent(analytics, 'otp_verification_attempt', { mobileNumber: signUpData.mobileNumber }); // Log OTP verification attempt
      ToastInfo('Please fill the otp');
    }
  };

  const resendOtpHandler = () => {
    logEvent(analytics, 'otp_resend_attempt', { mobileNumber: signUpData.mobileNumber }); // Log OTP resend attempt
    if (signUpData.mobileNumber) {
      let currentApiUrl = `${apiUrl}/${userType}/resend/otp?mobileNumber=${signUpData.mobileNumber}`;
      ApiHandling.fetchApi(currentApiUrl, {}, 'PUT').then((response) => {
        if (response?.status === 200) {
        }
      });
    } else {
      ToastInfo('Please enter mobile number');
    }
  };

  useEffect(() => {
    if (userType === 'agent') {
      setSignUpData({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        password: '',
        confirmPassword: '',
      });
    } else if (userType === 'builder') {
      setSignUpData({
        registeredName: '',
        displayName: '',
        mobileNumber: '',
        password: '',
        confirmPassword: '',
      });
    } else if (userType === 'marketingAgency') {
      setSignUpData({
        registeredName: '',
        displayName: '',
        mobileNumber: '',
        password: '',
        confirmPassword: '',
      });
    }
    setIsOtpSent(false);
    setOtp('');
  }, [userType]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Buddy!</h5>
                        <p>Sign up to continue</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <div className="px-2">
                  <ButtonGroup
                    style={{
                      textAlign: 'center',
                      marginTop: '15px',
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      style={{
                        outline: 'none !important',
                        background: 'none',
                        border: '2px solid',
                        padding: '10px',
                        borderRadius: '15px',
                        height: '100%',
                        margin: '10px',
                        flex: '1 1 0',
                        borderColor: userType === 'agent' ? '#005fad' : 'gray',
                      }}
                      onClick={() => setUserType('agent')}
                    >
                      <img
                        width="50"
                        height="50"
                        padding="10"
                        src={`https://img.icons8.com/external-goofy-solid-kerismaker/100/${userType === 'agent' ? '005fad' : '737373'
                          }/external-Agent-real-estate-goofy-solid-kerismaker.png`}
                        alt="external-Agent-real-estate-goofy-solid-kerismaker"
                      />
                      <div
                        style={{
                          color: userType === 'agent' ? '#005fad' : 'gray',
                        }}
                      >
                        AGENT
                      </div>
                    </Button>

                    <Button
                      style={{
                        outline: 'none !important',
                        border: '2px solid',
                        padding: '10px',
                        background: 'none',
                        margin: '10px',
                        borderRadius: '15px',
                        flex: '1 1 0',
                        borderColor:
                          userType === 'builder' ? '#005fad' : 'gray',
                      }}
                      onClick={(e) => setUserType('builder')}
                    >
                      <img
                        width="50"
                        height="50"
                        src={`https://img.icons8.com/external-simple-solid-edt.graphics/100/${userType === 'builder' ? '005fad' : '737373'
                          }/external-builder-avatars-simple-solid-edt.graphics.png`}
                        alt="external-builder-avatars-simple-solid-edt.graphics"
                      />
                      <div
                        style={{
                          color: userType === 'builder' ? '#005fad' : 'gray',
                        }}
                      >
                        BUILDER
                      </div>
                    </Button>
                    <Button
                      style={{
                        outline: 'none !important',
                        border: '2px solid',
                        background: 'none',
                        margin: '10px',
                        padding: '10px',
                        flex: '1 1 0',
                        borderRadius: '15px',
                        borderColor:
                          userType === 'marketingAgency' ? '#005fad' : 'gray',
                      }}
                      onClick={(e) => setUserType('marketingAgency')}
                    >
                      <img
                        width="50"
                        height="50"
                        src={`https://img.icons8.com/pastel-glyph/100/${userType === 'marketingAgency' ? '005fad' : '737373'
                          }/commercial--v4.png`}
                        alt="commercial--v4"
                      />
                      <div
                        style={{
                          color:
                            userType === 'marketingAgency' ? '#005fad' : 'gray',
                        }}
                      >
                        MARKETING
                      </div>
                    </Button>
                  </ButtonGroup>
                </div>

                <AvForm>
                  {userType === 'agent' && (
                    <CardBody style={{ paddingTop: '0px' }}>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="firstName" required>
                            First Name
                          </label>
                          <br />
                          <AvField
                            disabled={isOtpSent}
                            placeholder="Please Enter First Name"
                            className="w-100"
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={signUpData.firstName}
                            onChange={(e) => onInputChange(e, 'firstName')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="lastName" required>
                            Last Name
                          </label>
                          <AvField
                            disabled={isOtpSent}
                            placeholder="Please Enter Last Name"
                            className="w-100"
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={signUpData.lastName}
                            onChange={(e) => onInputChange(e, 'lastName')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="mobileNumber" required>
                            Mobile Number
                          </label>
                          <AvField
                            disabled={isOtpSent}
                            type="text"
                            placeholder="Please Enter Mobile Number"
                            className="w-100"
                            id="mobileNumber"
                            name="mobileNumber"
                            value={signUpData.mobileNumber}
                            onChange={(e) => onInputChange(e, 'mobileNumber')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                              pattern: {
                                value: /^\d{10}$/,
                                errorMessage:
                                  'Please enter a valid 10-digit mobile number.',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="password" required>
                            Password
                          </label>
                          <AvField
                            type="password"
                            disabled={isOtpSent}
                            placeholder="Please Enter Password"
                            className="w-100"
                            id="password"
                            name="password"
                            value={signUpData.password}
                            onChange={(e) => onInputChange(e, 'password')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="confirmPassword" required>
                            Confirm Password
                          </label>
                          <AvField
                            type="password"
                            disabled={isOtpSent}
                            placeholder="Please Enter Confirm Password"
                            className="w-100"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={signUpData.confirmPassword}
                            onChange={(e) =>
                              onInputChange(e, 'confirmPassword')
                            }
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>
                      {isOtpSent ? (
                        <div className="p-2">
                          <div className="p-field col-12">
                            <label htmlFor="otp" required>
                              OTP
                            </label>
                            <AvField
                              type="number"
                              placeholder="Please Enter Otp"
                              className="w-100"
                              id="otp"
                              name="otp"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'This field is required.',
                                },
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      <Row className="form-group mt-4">
                        <Col className="text-right mr-3">
                          {isOtpSent ? (
                            <>
                              <button
                                style={{
                                  color: '#004A87',
                                  background: 'none',
                                  border: 'none',
                                  cursor: 'pointer',
                                  marginRight: '5px',
                                  transition: 'color 0.3s',
                                  fontWeight: '500',
                                }}
                                onMouseOver={(e) =>
                                  (e.target.style.color = '#002E5D')
                                }
                                onMouseOut={(e) =>
                                  (e.target.style.color = '#004A87')
                                }
                                onClick={resendOtpHandler}
                              >
                                Resend otp
                              </button>
                              <button
                                onClick={() => verifyOtp()}
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Verify Otp
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => signUp()}
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Sign Up
                              </button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  )}
                </AvForm>
                <AvForm>
                  {userType === 'builder' && (
                    <CardBody style={{ paddingTop: '0px' }}>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="registeredName" required>
                            Registered Name
                          </label>
                          <br />
                          <AvField
                            disabled={isOtpSent}
                            placeholder="Please Enter Registered Name"
                            className="w-100"
                            type="text"
                            id="registeredName"
                            name="registeredName"
                            value={signUpData.registeredName}
                            onChange={(e) => onInputChange(e, 'registeredName')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="displayName" required>
                            Display Name
                          </label>
                          <AvField
                            disabled={isOtpSent}
                            placeholder="Please Enter Display Name"
                            className="w-100"
                            type="text"
                            id="displayName"
                            name="displayName"
                            value={signUpData.displayName}
                            onChange={(e) => onInputChange(e, 'displayName')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="mobileNumber" required>
                            Mobile Number
                          </label>
                          <AvField
                            disabled={isOtpSent}
                            placeholder="Please Enter Mobile Number"
                            className="w-100"
                            type="text"
                            id="mobileNumber"
                            name="mobileNumber"
                            value={signUpData.mobileNumber}
                            onChange={(e) => onInputChange(e, 'mobileNumber')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                              pattern: {
                                value: /^\d{10}$/,
                                errorMessage:
                                  'Please enter a valid 10-digit mobile number.',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="password" required>
                            Password
                          </label>
                          <AvField
                            type="password"
                            disabled={isOtpSent}
                            placeholder="Please Enter Password"
                            className="w-100"
                            id="password"
                            name="password"
                            value={signUpData.password}
                            onChange={(e) => onInputChange(e, 'password')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="confirmPassword" required>
                            Confirm Password
                          </label>
                          <AvField
                            type="password"
                            disabled={isOtpSent}
                            placeholder="Please Enter Confirm Password"
                            className="w-100"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={signUpData.confirmPassword}
                            onChange={(e) =>
                              onInputChange(e, 'confirmPassword')
                            }
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>

                      {isOtpSent && (
                        <div className="p-2">
                          <div className="p-field col-12">
                            <label htmlFor="otp">OTP</label>
                            <AvField
                              type="number"
                              placeholder="Please Enter OTP"
                              className="w-100"
                              id="otp"
                              name="otp"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'This field is required.',
                                },
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <Row className="form-group mt-4">
                        <Col className="text-right mr-3">
                          {isOtpSent ? (
                            <>
                              <button
                                style={{
                                  color: '#004A87',
                                  background: 'none',
                                  border: 'none',
                                  cursor: 'pointer',
                                  marginRight: '5px',
                                  transition: 'color 0.3s',
                                  fontWeight: '500',
                                }}
                                onMouseOver={(e) =>
                                  (e.target.style.color = '#002E5D')
                                }
                                onMouseOut={(e) =>
                                  (e.target.style.color = '#004A87')
                                }
                                onClick={() => resendOtpHandler()}
                              >
                                Resend OTP
                              </button>
                              <button
                                onClick={() => verifyOtp()}
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Verify OTP
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => signUp()}
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Sign Up
                            </button>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  )}
                </AvForm>
                <AvForm>
                  {userType === 'marketingAgency' && (
                    <CardBody style={{ paddingTop: '0px' }}>
                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="registeredName" required>
                            Registered Name
                          </label>
                          <br />
                          <AvField
                            disabled={isOtpSent}
                            placeholder="Please Enter Registered Name"
                            className="w-100"
                            type="text"
                            id="registeredName"
                            name="registeredName"
                            value={signUpData.registeredName}
                            onChange={(e) => onInputChange(e, 'registeredName')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="displayName" required>
                            Display Name
                          </label>
                          <AvField
                            disabled={isOtpSent}
                            placeholder="Please Enter Display Name"
                            className="w-100"
                            type="text"
                            id="displayName"
                            name="displayName"
                            value={signUpData.displayName}
                            onChange={(e) => onInputChange(e, 'displayName')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="mobileNumber" required>
                            Mobile Number
                          </label>
                          <AvField
                            disabled={isOtpSent}
                            placeholder="Please Enter Mobile Number"
                            className="w-100"
                            type="text"
                            id="mobileNumber"
                            name="mobileNumber"
                            value={signUpData.mobileNumber}
                            onChange={(e) => onInputChange(e, 'mobileNumber')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                              pattern: {
                                value: /^\d{10}$/,
                                errorMessage:
                                  'Please enter a valid 10-digit mobile number.',
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="password" required>
                            Password
                          </label>
                          <AvField
                            type="password"
                            disabled={isOtpSent}
                            placeholder="Please Enter Password"
                            className="w-100"
                            id="password"
                            name="password"
                            value={signUpData.password}
                            onChange={(e) => onInputChange(e, 'password')}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="">
                        <div className="p-field col-12">
                          <label htmlFor="confirmPassword" required>
                            Confirm Password
                          </label>
                          <AvField
                            type="password"
                            disabled={isOtpSent}
                            placeholder="Please Enter Confirm Password"
                            className="w-100"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={signUpData.confirmPassword}
                            onChange={(e) =>
                              onInputChange(e, 'confirmPassword')
                            }
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'This field is required.',
                              },
                            }}
                          />
                        </div>
                      </div>

                      {isOtpSent && (
                        <div className="">
                          <div className="p-field col-12">
                            <label htmlFor="otp" required>
                              OTP
                            </label>
                            <AvField
                              type="number"
                              placeholder="Please Enter OTP"
                              className="w-100"
                              id="otp"
                              name="otp"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'This field is required.',
                                },
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <Row className="form-group mt-4">
                        <Col className="text-right mr-3">
                          {isOtpSent ? (
                            <>
                              <button
                                style={{
                                  color: '#004A87',
                                  background: 'none',
                                  border: 'none',
                                  cursor: 'pointer',
                                  marginRight: '5px',
                                  transition: 'color 0.3s',
                                  fontWeight: '500',
                                }}
                                onMouseOver={(e) =>
                                  (e.target.style.color = '#002E5D')
                                }
                                onMouseOut={(e) =>
                                  (e.target.style.color = '#004A87')
                                }
                                onClick={() => resendOtpHandler()}
                              >
                                Resend OTP
                              </button>
                              <button
                                onClick={() => verifyOtp()}
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Verify OTP
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => signUp()}
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Sign Up
                            </button>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  )}
                </AvForm>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{' '}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
